import styles from "./Home.module.css";
import image from "../../img/logo.png";
import { Link } from "react-scroll";
import React, { useEffect, useState } from "react";

const zap = "https://api.whatsapp.com/send?phone=5511976805330&text=Bem-vindo%20a%20Farmaco,%20podemos%20ajudar?"

export default function Home() {
  const [estaRodando, setEstaRolando] = useState(false);
  const [isHamburgerMenuOpen, setIsHamburgerMenuOpen] = useState(false);

  useEffect(() => {
    function handleScroll() {
      if (window.scrollY > 60) {
        setEstaRolando(true);
      } else {
        setEstaRolando(false);
      }
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header className={`${styles.header} ${estaRodando ? styles.Rolando : ""}`}>
      <div className={styles.menu}>
        <div className={styles.divHamburguer}>
          <button
            className={styles.hamburgerButton}
            onClick={() => setIsHamburgerMenuOpen(!isHamburgerMenuOpen)}
          >
            ☰
          </button>
          {isHamburgerMenuOpen && (
            <nav
              className={`${styles.mobileMenu} ${
                isHamburgerMenuOpen ? styles.open : ""
              }`}
            >
              <li className={styles.liMenu}>
                <Link
                  to="sobre"
                  spy={true}
                  smooth={true}
                  offset={50}
                  duration={1000}
                  className={styles.botao}
                  onClick={() => setIsHamburgerMenuOpen(false)}
                >
                  Sobre nós
                </Link>
              </li>
              <li className={styles.liMenu}>
                <Link
                  to="funcionalidades"
                  spy={true}
                  smooth={true}
                  offset={50}
                  duration={2500}
                  className={styles.botao}
                  onClick={() => setIsHamburgerMenuOpen(false)}
                >
                  Funcionalidades
                </Link>
              </li>
              <li className={styles.liMenu}>
                <Link
                  to="missao"
                  spy={true}
                  smooth={true}
                  offset={50}
                  duration={2500}
                  className={styles.botao}
                  onClick={() => setIsHamburgerMenuOpen(false)}
                >
                  Nossos conceitos
                </Link>
              </li>
              <li className={styles.liMenu}>
                <Link
                  to="solucoes"
                  spy={true}
                  smooth={true}
                  offset={50}
                  duration={2500}
                  className={styles.botao}
                  onClick={() => setIsHamburgerMenuOpen(false)}
                >
                  Soluções
                </Link>
              </li>              
              <li className={styles.liMenu}>
                <Link
                  to="clientes"
                  spy={true}
                  smooth={true}
                  offset={50}
                  duration={2500}
                  className={styles.botao}
                  onClick={() => setIsHamburgerMenuOpen(false)}
                >
                  Clientes e parceiros
                </Link>
              </li>
            </nav>
          )}
          </div>
          <img src={image} className={styles.img} alt="Logotipo" />
          <div>

          <a
            href={zap}
            className={styles.btn}
          >
            CONTATO
          </a>
        </div>
      </div>
      <div className={styles.divHome}>
        <div className={styles.divImage}>
          <img src={image} className={styles.img} alt="Logotipo" />
        </div>
        <nav className={styles.nav}>
          <li className={styles.li}>
            <Link
              to="sobre"
              spy={true}
              smooth={true}
              offset={50}
              duration={2500}
              className={styles.botao}
            >
              Sobre nós
            </Link>
          </li>
          <li className={styles.li}>
            <Link
              to="funcionalidades"
              spy={true}
              smooth={true}
              offset={50}
              duration={2500}
              className={styles.botao}
            >
              Funcionalidades
            </Link>
          </li>
          <li className={styles.li}>
            <Link
              to="funcionalidades"
              spy={true}
              smooth={true}
              offset={50}
              duration={2500}
              className={styles.botao}
            >
              Nossos conceitos
            </Link>
          </li>
          <li className={styles.li}>
            <Link
              to="solucoes"
              spy={true}
              smooth={true}
              offset={50}
              duration={2500}
              className={styles.botao}
            >
              Soluções
            </Link>
          </li>
          <li className={styles.li}>
            <Link
              to="clientes"
              spy={true}
              smooth={true}
              offset={50}
              duration={2500}
              className={styles.botao}
            >
              Clientes e parceiros
            </Link>
          </li>
        </nav>
        <div className={styles.caixaBtn}>
          <a
            href={zap}
            className={styles.btn}
          >
            CONTATO
          </a>
        </div>
      </div>
    </header>
  );
}
