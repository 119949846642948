import css from "./Funcionalidades.module.css";
import teraputica from "../../img/teraputica.png";
import economica from "../../img/economica.png";
import farmaceutica from "../../img/farmaceutica.png";

export default function Funcionalidades() {
  return (
    <section className={css.section} id="funcionalidades">
      <h2 className={css.h2}>Funcionalidades</h2>
      <div className={css.figure}>
        <div className={css.container}>
          <div className={css.box}>
            <img className={css.fig} src={farmaceutica} alt="farmaceutica" />
            <div className={css.boxL}>
              <h3 className={css.h3}>Análise farmacêutica</h3>
              <p className={css.p}>
                Conheça e compare formas farmacêuticas, concentrações,
                fabricantes, unidades de dose por embalagem, formulações e
                outros detalhes.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className={css.figure}>
        <div className={css.container}>
          <div className={css.box}>
            <div className={css.boxL}>
              <h3 className={css.h3}>Análise terapêutica</h3>
              <p className={css.p}>
                Conheça e compare grupos farmacológicos, classificação de ação
                terapêutica e outros detalhes.
              </p>
            </div>
            <img className={css.fig} src={teraputica} alt="terapeutica" />
          </div>
        </div>
      </div>
      <div className={css.figure}>
        <div className={css.container}>
          <div className={css.box}>
            <img className={css.fig} src={economica} alt="economica" />
            <div className={css.boxL}>
              <h3 className={css.h3}>Análise econômica</h3>
              <p className={css.p}>
                Conheça e compare preços regulados pelo governo, preços
                indicados pelo mercado, custo de dose média definida e outros
                detalhes.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
