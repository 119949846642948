import css from "./Rodape.module.css";
import logo from "../../img/logo.webp";
import inovativa from "../../img/inovativa (1).webp";
import insta from "../../img/instagram.svg";
import linked from "../../img/linked.svg";
import selo from "../../img/selo.webp";

export default function Rodape() {
  return (
    <footer className={css.footer}>
      <section className={css.section}>
        <div className={css.divSocial}>
          <p>Siga nossas redes sociais:</p>
          <a href="https://instagram.com/farmaco.io?igshid=dnJka2V3MjA2aGE1">
            <img src={insta} alt="telefone" />
          </a>
          <a href="https://www.linkedin.com/company/farma-co/">
            <img src={linked} alt="telefone" />
          </a>
        </div>
        <div className={css.Logo}>
          <img src={logo} alt="logo" />
        </div>
        <div className={css.divLogo}>
          <div>
            <p>Empresa acelerada:</p>
          </div>
          <div>
            <img src={inovativa} alt="Inovativa" />
          </div>
          <div>
            <img src={selo} alt="selo" />
          </div>
        </div>
      </section>
      <div className={css.assinatura}>
        <p className={css.assinaturaTexto}>
          ©2024 Todos os direitos reservado Farmaco | Desenvolvido por
        </p>
        <a href="http://silasmariosa.com.br/" className={css.assinaturaAutor}>
          Mariosa Tech
        </a>
      </div>
    </footer>
  );
}
