import css from "./BannerTopo.module.css";
import image from "../../img/medica.png";

export default function BannerTopo() {  
  return (
    <section className={css.section} id="home">
      <div className={css.boxContainer}>
        <div className={css.container}>
          <div className={css.divTexto}>
            <h1 className={css.titulo}>
            SOMOS UMA PLATAFORMA DE <h3 className={css.h3Titulo}>INFORMAÇÕES DE MEDICAMENTOS</h3> PARA ATENDER: 
            </h1>  
            <h1 className={css.titulo}>
            <span></span>
            </h1>         
          </div>
        </div>
      </div>
          <diiv className={css.divImg}>
            <img src={image} alt="medica logo" className={css.img} />
          </diiv>
    </section>
  );
}
