import css from "./Formulario.module.css";
import React ,{useState} from "react";
import { useForm } from "react-hook-form";
import { isEmail } from "validator";

export default function Formulario() {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const [loading, setLoading] = useState(false);

  const onSubmit = async (data) => {
    try {
      setLoading(true)
      const response1 = await fetch("https://formsubmit.co/ajax/suporte@farmaco.io", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(data),
      });
      const response2 = await fetch("https://formsubmit.co/ajax/claudio@farmaco.io", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(data),
      });

      if (response1.ok && response2.ok) {
        const responseData1 = await response1.json();
        const responseData2 = await response2.json();
        console.log(responseData1, responseData2);
        reset();
      } else {
        console.error("Erro no envio do formulário");
      }
    } catch (error) {
      console.error("Erro no envio do formulário:", error);
    } finally {
      setLoading(false);
    }
  };
  
  return (
    <section className={css.section}>
      <h2 className={css.h2}>Contato</h2>
      <form onSubmit={handleSubmit(onSubmit)} className={css.form}>
        <div className={css.formgroup}>
          <label>Nome</label>
          <input
            className={errors?.name && css.inputerror}
            type="text"
            placeholder="Seu nome"
            {...register("name", { required: true })}
          />
          {errors?.name?.type === "required" && (
            <p className={css.errormessage}>Preencha o campo nome.</p>
          )}
        </div>
        <div className={css.formgroup}>
          <label>Empresa ou instituição</label>
          <input
            className={errors?.empresa && css.inputerror}
            type="text"
            placeholder="Nome da empresa ou instituição"
            {...register("empresa", { required: true })}
          />
          {errors?.name?.type === "required" && (
            <p className={css.errormessage}>Preencha o campo nome da empresa ou instituição.</p>
          )}
        </div>
        <div className={css.formgroup}>
          <label>Cargo</label>
          <input
            className={errors?.empresa && css.inputerror}
            type="text"
            placeholder="Qual seu cargo?"
            {...register("empresa", { required: true })}
          />
          {errors?.name?.type === "required" && (
            <p className={css.errormessage}>Preencha o campo cargo.</p>
          )}
        </div>

        <div className={css.formgroup}>
          <label>E-mail</label>
          <input
            className={errors?.email && css.inputerror}
            type="email"
            placeholder="Seu e-mail"
            {...register("email", {
              required: true,
              validate: (value) => isEmail(value),
            })}
          />
          {errors?.email?.type === "required" && (
            <p className={css.errormessage}>Preencha o campo e-mail.</p>
          )}
        </div>
        <div className={css.formgroup}>
          <label>Whatsapp</label>
          <input
            className={errors?.tel && css.inputerror}
            type="tel"
            placeholder="Seu número de whatsapp"
            {...register("tel", { required: true })}
          ></input>
          {errors?.tel?.type === "required" && (
            <p className={css.errormessage}>Preencha o campo telefone.</p>
          )}
        </div>
        <div className={css.formgroup}>
          <label>Menssagem</label>
          <textarea
            className={errors?.menssagem && css.textareaerror && css.msg}
            type="text"
            placeholder="Seu texto aqui..."
            {...register("menssagem", { max: 500 })}
          ></textarea>
          {errors?.menssagem?.type === "required" && (
            <p className={css.errormessage}>Máximo 500 caracteres.</p>
          )}
        </div>
        <div className={css.formgroup}>
          <div className={css.checkboxgroup}>
            <input
              type="checkbox"
              name="privacy-policy"
              {...register("privacyTerms", {
                validate: (value) => value === true,
              })}
            />
            <label>Eu concordo com os termos de privacidade.</label>
          </div>
          {errors?.privacyTerms?.type === "validate" && (
            <p className={css.errormessage}>
              Você deve aceitar os termos de privacidade.
            </p>
          )}
        </div>
        <div className={css.formgroup}>
        <button type="submit">{loading ? "Enviando..." : "Enviar"}</button>
      </div>
      </form>
    </section>
  );
}
