import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { createGlobalStyle } from 'styled-components';
import Home from './components/Home';
import BannerTopo from './components/BannerTopo';
import './styles.css'
import Funcionalidades from './components/Funcionalidades';
import Solucoes from './components/Solucoes';
import Carrossel from './components/Carrossel';
import Formulario from './components/Formulario';
import Rodape from './components/Rodape';
import Sobre from './components/Sobre';
import Missao from './components/Missao';


const GlobalStyles = createGlobalStyle`
body {
  box-sizing: border-box;
  margin: 0;
  font-family: -apple-system, 'Montserrat', 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
li {
  list-style: none;
}
`

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <GlobalStyles/>
      <Home/>
      <BannerTopo/>
      <Sobre/>
      <Funcionalidades/>
      <Missao/>
      <Solucoes/>
      <Carrossel/>
      <Formulario/>
      <Rodape/>
  </React.StrictMode>
)
reportWebVitals();
