import css from "./Missao.module.css";
import textos from "./missao.json";

import "swiper/swiper-bundle.css";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";

export default function Missao() {
  return (
    <section className={css.section} id="missao">
      <a className={css.tituloTopo}>Nossos conceitos</a>
      <div className={css.divVisao}>
        <li>
          <h2>Missão</h2>
          <p>Oferecer informações de medicamentos da mais alta qualidade</p>
        </li>
        <li>
          <h2>Visão</h2>
          <p>Ser reconhecida nacionalmente como referência em informações de
medicamentos, pela sua expertise, soluções e melhores práticas.</p>
        </li>
      </div>
      <div className={css.divMission}>
      <h3>Valores</h3>
        {textos.map((dados) => (
          <div key={dados.id}>
            <h4>{dados.titulo}</h4>
            <p>"{dados.texto}"</p>
          </div>
        ))}
      </div>
    </section>
  );
}
