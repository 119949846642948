import css from "./Sobre.module.css";

import "swiper/swiper-bundle.css";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";

const dados = [
  {
    title: "O que é?",
    content:
      "A Farmaco é uma startup que atua com informações de medicamentos e que visa atender todo o ecossistema farmacêutico.",
  },
  {
    title: "Qual foco?",
    content:
      "Reunindo informações que impactam diretamente na ciência, na educação e nos negócios relacionados a medicamentos, a Farmaco realiza a validação, a organização, a padronização e a consolidação de um conjunto crescente de informações de medicamentos comercializados no Brasil, nos domínios farmacêutico, terapêutico e econômico.",
  },
  {
    title: "Como usar?",
    content:
      "Através de um SaaS ou APIaaS as informações disponibilizadas pela Farmaco podem ser filtradas em segundos em seu sistema e essa facilidade gera automaticamente economia de tempo, economia financeira, assertividade em pesquisas sobre medicamentos e a comodidade frente ao universo complexo de informações de medicamentos no Brasil.",
  },
  {
    title: "Idealizador",
    content:
      "Idealizada pelo farmacêutico, mestre e doutor, Eugênio Neves, que ao longo de mais de 25 anos atuou com a criação de sistemas tecnológicos de informações de medicamentos.",
  },
  {
    title: "A quem se destina?",
    content:
      "Essas informações ajudam empresas e profissionais a conhecerem opções de troca de medicamentos, formas farmacêuticas, fabricantes de medicamentos, variações de preço por medicamento, aspectos regulatórios de medicamentos, informações de armazenamento, análise de rentabilidade de medicamentos e muito mais.",
  },
];

export default function Sobre() {
  return (
    <section className={css.sectionContainer} id="sobre">
      <h2>Sobre nós</h2>
      <Swiper
        spaceBetween={20}        
        autoplay={false}
        centeredSlides={false}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
        }}
        navigation={false}
        modules={[Pagination]}
        className="mySwiper"
      >
        {dados.map((item, index) => (
          <SwiperSlide key={index}>
            <div key={index} className={css.divContainer}>
              <h3 className={css.title}>{item.title}</h3>
              <div className={css.boxTexte}>
                <p className={css.texto}>{item.content}</p>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
}
