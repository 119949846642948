import css from "./Solucoes.module.css";
import image from '../../img/pc.png'
import image2 from '../../img/apiaas.png'

export default function Solucoes() {
  return (
    <section className={css.section} id="solucoes">
      <h2 className={css.h2}>Soluções</h2>
      <div className={css.containerLeft}>
        <div className={css.boxLeft}>
          <div className={css.boxTituloLeft}>
            <p className={css.tituloLeft}>SaaS</p>
          </div>
          <img className={css.imgMobileL} src={image} alt="monitor imagem"/>
          <p className={css.textoLeft}>
            O Software como um Serviço (SaaS) da Farmaco é uma ferramenta que
            ajuda profissionais e instituições a conhecerem opções de troca de
            medicamentos, formas farmacêuticas, fabricantes de medicamentos,
            variações de preço por medicamento, aspectos regulatórios de
            medicamentos, informações de armazenamento, análise de rentabilidade
            de medicamentos e muito mais.
          </p>
        </div>
        <img className={css.img} src={image} alt="monitor imagem"/>
      </div>
      <div className={css.containerRight}>
        <img className={css.img} src={image2} alt="monitor imagem"/>
        <div className={css.boxRight}>
          <div className={css.boxTituloRight}>
            <p className={css.tituloRight}>APIaaS*</p>
          </div>
          <img className={css.imgMobileR} src={image2} alt="monitor imagem"/>
          <p className={css.textoRight}>
          A Interface de Programação de Aplicativos como um Serviço (APIaaS) da Farmaco é uma ferramenta que ajuda empresas que precisam acessar um banco de dados validado e atualizado de medicamentos no Brasil. Em razão do dinamismo do mercado de medicamentos as informações sobre medicamentos estão em constante mudança o que gera instabilidade a qualquer empresa que precise de tais informações para que seu negócio principal funcione adequadamente.
          </p>
        </div>
      </div>
    </section>
  );
}
