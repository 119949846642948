import css from "./Carrossel.module.css";
import imagens from "./parceiros.json";

import "swiper/swiper-bundle.css";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";

export default function Carrossel() {
  return (
    <section className={css.section} id="clientes">
      <h2 className={css.h2}>Clientes e parceiros</h2>
      {/* <h3 className={css.h3}>
        Temos nessas empresas <strong className={css.strong}>clientes</strong>,{" "}
        <strong className={css.strong}>parceiros</strong> e{" "}
        <strong className={css.strong}>amigos</strong>:
      </h3> */}
      <Swiper
        spaceBetween={10}
        centeredSlides={false}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={false}
        breakpoints={{
          640: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 5,
            spaceBetween: 50,
          },
        }}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        {imagens.map((dados) => (
          <SwiperSlide key={dados.id}>
            <div className={css.boxImg}>
              <img className={css.img} src={dados.pic} alt={dados.titulo} />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
}
